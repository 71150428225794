<template>
  <el-dialog class="operation_dialog " v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false"
    :width="dialogVisiblewidth">
    <template v-if="!state.successIs">
      <div class="amount mb-15">支付金额：<span>¥{{state.data.rechargeAmountCn}}</span></div>
      <div class="line mb-24"></div>
      <div class="title mb-22">充值方式</div>
      <modes ref="modesRef" :showType="2" class="mb-20" @Emit="($event) => { state.rechargeMethod = $event }"
        :disabled="true"></modes>
      <div class="w-188 h-188 codebox dp-fc mb-20" v-loading="loading" :element-loading-text="config.loading.text"
        :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background">
        <div class="line"></div>
        <img class="w-136 h-136" :src="state.codeimg">
      </div>
    </template>
    <template v-else>
      <div class="dp-f fw-w jc-c successbox">
        <img class="w-80 h-80 mb-30" src="@/assets/img/account/success.png">
        <div class="w100 ta-c">支付成功，刷新当前页面</div>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits,onMounted,watch } from 'vue'
import { handleMessage } from "@/utils/server/confirm";
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import qs from "qs";
import modes from "../topupsubject/modes.vue"//选择充值方式
const loading = ref(false);//loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const dialogVisiblewidth = ref('460px')//弹框宽度
const emit = defineEmits(['Emit'])
const modesRef = ref()
const state = reactive({
  rechargeMethod:0,
  successIs: false,//是否支付成功
  data: {},
  codeimg: '',
  timmer: null
})
// 支付成功
const paySuccess = () => {
  dialogVisiblewidth.value = '340px'
  dialogVisibletitle.value = '支付成功'
  state.successIs = true
}

// 获取新的二维码
const getNewCode = () => {
  nextTick(() => {
    loading.value = true
    state.codeimg = ''
    queryResourceTable('/admin/account/getQRCodeById', qs.stringify({
      id: state.data.id
    })).then((res) => {
      state.codeimg = res.data.qrbaseStr
      state.timmer = setInterval(() => {
        httpToken({
          method: "post",
          url: '/admin/account/queryOrderStatus',
          data: qs.stringify({
            number: state.data.number
          })
        }).then((res) => {
          if (res.data == 2) {
            window.clearInterval(state.timmer); //清除定时器
            state.timmer = null;
            handleMessage('支付成功', 'success')
            dialogVisible.value=false
            setTimeout(() => {
              emit('Emit')
            }, 2000);
          }
        })
      }, 1000)
      loading.value = false;
    }).catch((err) => {
      if (err.message != 'stop')
        loading.value = false;
    })
  })
}
const show = ((data) => {
  state.successIs = false
  state.data = data
  dialogVisibletitle.value = '支付'
  dialogVisible.value = true
  // 支付渠道（0：支付宝，1：微信，2：线下汇款，3：余额支付）
  nextTick(() => {
    if (data.paymentMethod == 1) {
      unref(modesRef).setIndex(1)
    } else {
      unref(modesRef).setIndex(0)
    }
  })
  getNewCode()
})
onMounted(() => {
  watch(() => dialogVisible.value, () => {
    console.log('监听dialogVisible.value',dialogVisible.value)
    if(dialogVisible.value==false){
      window.clearInterval(state.timmer); //清除定时器
    }
  })
})
defineExpose({
  show,
});
</script>
<style lang="scss" scoped>
.amount {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

  span {
    font-size: 20px;
    font-weight: 600;
    color: #FF0000;
  }
}

.line {
  width: 100%;
  height: 1px;
  background: #E9E9E9;
}

.title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.codebox {
  border: 1px solid #DFDFDF;
  position: relative;
  overflow: hidden;
  // border-image-source: radial-gradient(60% 65%, transparent 0px, transparent 100%, #8C5234 100%);
  // border-image-slice: 1;
  // border-width: 2px;
  // border-style: solid;
  // border-image-outset: 0cm;
  .line {
    position: absolute;
    height: calc(100% - 2px);
    width: 100%;
    // background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00AEF9 211%);
    background: linear-gradient(180deg, rgba(152, 231, 255, 0) 0%, #95E6FF 100%);
    border-bottom: 3px solid #00AEF9;
    transform: translateY(-100%);
    animation: radar-beam linear 2s infinite;
    animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
    animation-delay: 1s;
  }
}
@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(100%);
  }
}
.successbox {
  padding-top: 50px;
  padding-bottom: 70px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}</style>