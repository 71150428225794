<template>
  <div class="w100 bg-fff pl-20 pr-20 pt-20 pb-20">
    <div class="w100 dp-f fw-w mb-24">
      <div class="input_box w-200">
        <div class="input_title">订单类型：</div>
        <el-select v-model="state.searchKey.rechargeType" class="input" size="large">
          <el-option v-for="item in state.rechargeTypeData" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-200">
        <div class="input_title">支付状态：</div>
        <el-select v-model="state.searchKey.paymentStatus" class="input" size="large">
          <el-option v-for="item in state.paymentStatusData" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="input_box w-450" style="margin-right: 0;">
        <div class="input_title">支付时间：</div>
        <el-date-picker class="input" v-model="state.searchKey.rechargeDate" type="datetimerange" range-separator="→"
          start-placeholder="开始日期" end-placeholder="结束日期" value-format="YYYY-MM-DD HH:mm:ss" />
      </div>
      <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="searcMR ml-at"
        @buttonclick="getdataList()"></oabutton>
    </div>
    <el-table :data="state.Notice" class="mb-20 table"
      :header-cell-style="{ background: 'var(--active-alpha)', color: config.table.color, fontWeight: config.table.fontWeight, fontSize: config.table.fontSize }"
      row-key="id" v-loading="loading" :element-loading-text="config.loading.text"
      :element-loading-spinner="config.loading.spinner">
      <el-table-column label="" width="20" />
      <el-table-column :align="'center'" prop="number" label="订单编号" />
      <el-table-column :align="'center'" prop="rechargeTypeCn" label="订单类型" />
      <el-table-column :align="'center'" label="套餐金额">
				<template #default="scope">
					<div class="dp-fc" v-if="scope.row.rechargeAmount">
						¥{{ scope.row.rechargeAmountCn }}
					</div>
				</template>
			</el-table-column>
      <!-- <el-table-column prop="rechargeDate" label="充值时间" /> -->
      <el-table-column :align="'center'" prop="paymentStatusCn" label="支付状态" width="160" />
      <el-table-column :align="'center'" prop="paymentMethodCn" label="支付渠道" width="160" />
      <el-table-column :align="'center'" prop="paymentDate" label="支付时间" />
      <el-table-column :align="'center'" prop="invoicingCn" label="开票" />
      <el-table-column :align="'center'" label="操作" width="250">
        <template #default="scope">
          <div class="dp-fc butbox fw-w">
            <!-- <div class="Editor_span" v-permission="'account_center:get'">查看</div> -->
            <div class="Editor_span" v-permission="'account_center:pay'" v-if="scope.row.paymentStatus==1&&scope.row.rechargeType!=1" @click="payClick(scope.row)">支付</div>
            <div class="cu-p" v-permission="'account_center:cancel'" @click="cancel(scope.row)" v-if="scope.row.paymentStatus==0">取消</div>
            <!-- @click="router_push_name('account_invoice',{type:'drawbill',row:JSON.stringify(scope.row)},undefined,true)" -->
            <div class="Delete_span" v-permission="'account_center:drawbill'" v-if="scope.row.paymentStatus==2&&scope.row.invoicing==0" @click="router_push_name('account_invoice',{type:'drawbill',row:JSON.stringify(scope.row)},undefined,true)">去开票</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="dp-f jc-c" @current-change="handleCurrentChange" background layout="prev, pager, next,slot"
      :current-page="currentPage" :total="totalPage">
    </el-pagination>
  </div>
  <pay ref="payRef" @Emit="getdataList(1)"></pay>
</template>
<script setup>
import { reactive, ref, unref } from 'vue'
import { httpToken } from "@/utils/request";
import { queryResourceTable } from "@/api/cancelToken.js"
import {getrechargeTypeData,getpaymentStatusData} from "@/utils/server/selectdata"
import {getrechargeType,getpaymentMethod,getpaymentStatus,getinvoicing} from "@/utils/server/getcode"
import { convert } from "@/utils/util"
import {router_push_name} from "@/utils/server/router"
import { handleCofirm, handleMessage } from "@/utils/server/confirm";
import qs from "qs";
import pay from "./pay.vue"//支付弹框
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const payRef=ref()//支付弹框
const state = reactive({
  searchKey: {
    rechargeType: '',//订单类型
    paymentStatus: '',//支付状态
    rechargeDate:null,//充值时间
  },//搜索绑定值
  rechargeTypeData: getrechargeTypeData(2),
  paymentStatusData:getpaymentStatusData(2),
  Notice: [],//表格数据
})
// 点击支付
const payClick=(row)=>{
  unref(payRef).show(row)
}
// 取消
const cancel=(row)=>{
  handleCofirm('确定要取消支付吗?',undefined,'取消支付').then(() => {
		httpToken({
      method: "post",
      url: '/admin/account/toCancel',
      data: qs.stringify({
        id:row.id
      })
    }).then(() => {
      getdataList(currentPage.value)
    })
	})
}
// 列表调取 页数
const getdataList = ((val=1) => {
  currentPage.value = val;
  loading.value = true
  console.log("start query recharge", val)
  httpToken( {
    method: 'post',
    url: '/admin/account/selectRechargeRecord',
    data: qs.stringify({
        page: val,
        rechargeType:state.searchKey.rechargeType,
        paymentStatus:state.searchKey.paymentStatus,
        date:JSON.stringify(state.searchKey.rechargeDate)
      })
  } ).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getdataList(res.data.current)
      return
    }
    totalPage.value = res.data.total;
    res.data.records.forEach(item => {
      item.paymentDate=item.paymentDate?item.paymentDate:'/'
      item.rechargeTypeCn=getrechargeType(item.rechargeType)
      item.paymentStatusCn=getpaymentStatus(item.paymentStatus)
      item.paymentMethodCn=getpaymentMethod(item.paymentMethod)
      item.invoicingCn=getinvoicing(item.invoicing)
      item.rechargeAmountCn=convert(item.rechargeAmount,false)
    });
    state.Notice = res.data.records
    loading.value = false;
  }).catch((err) => {
    if (err.message != 'stop')
      loading.value = false;
  })
})
getdataList()
const handleCurrentChange=((val)=>{
  getdataList(val)
})
</script>
<style lang="scss" scoped >
.butbox {
  div+div {
    border-left: 1px solid rgba(216, 216, 216, 1);
    padding-left: 10px;
    margin-left: 10px;
  }

  :last-child {
    border-right: none;
  }
}

.table {
  min-height: 350px;
}
</style>